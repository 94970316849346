import { Box, Stack, Dropdown, FormControl, Flex } from '@lego/klik-ui';
import { useEffect, useMemo, useState } from 'react';
import { PdfViewer } from '../../components/PdfViewer';
import { Periode, PeriodeCarousel } from '../../components/PeriodeCarousel';
import { useKioskData } from '../../contexts/KioskDataProvider';
import { APP_SCOPE } from '../../utils/config/ConfigConstants';
import { generateSemiMonthPeriods, generateWeekPeriods } from '../../utils/datetime';
import { generatePayslipUrl } from '../../utils/functions/helperFunctions';
import { useTranslation } from 'react-i18next';

export const Payslips = () => {
  const { employeeData } = useKioskData();
  const { t } = useTranslation();
  const periodes: Periode[] = useMemo(() => {
    const isSemimonthly = employeeData?.company.indexOf('1056') === 0 ? true : false;
    const periodes = isSemimonthly
      ? generateSemiMonthPeriods(12, true)
      : generateWeekPeriods(26, true);
    periodes.reverse();
    const today = new Date();
    if (!isSemimonthly && today.getDay() < 4) {
      periodes.pop();
    }
    if (
      (isSemimonthly && today.getDate() > 0 && today.getDate() < 4) ||
      (isSemimonthly && today.getDate() > 15 && today.getDate() < 19)
    ) {
      periodes.pop();
    }
    return periodes;
  }, [employeeData?.company]);

  const [selectedPayslipType, setSelectedPayslipType] = useState<string>('regularPayment');

  const [offCyclePaymentDate, setOffCyclePaymentDate] = useState<string | undefined>(undefined);
  const [regularCyclePaymentDate, setRegularCyclePaymentDate] = useState<Periode>(
    periodes[periodes.length - 1],
  );

  const [pdfUrlOffCycle, setPdfUrlOffCycle] = useState<string | undefined>(undefined);
  const [pdfUrlRegularCycle, setPdfUrlRegularCycle] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (!employeeData) return;

    if (selectedPayslipType === 'regularPayment' && regularCyclePaymentDate) {
      setPdfUrlRegularCycle(
        generatePayslipUrl(
          regularCyclePaymentDate,
          employeeData?.extPayrollId || employeeData?.employeeNumber!,
        ),
      );
    }

    if (selectedPayslipType === 'offCyclePayment' && offCyclePaymentDate) {
      const offCyclePeriod: Periode = {
        id: `${offCyclePaymentDate}#${offCyclePaymentDate}`,
        display: `${offCyclePaymentDate} - ${offCyclePaymentDate}`,
        start: offCyclePaymentDate!,
        end: offCyclePaymentDate!,
      };
      const offCycleUrl: string | undefined = generatePayslipUrl(
        offCyclePeriod,
        employeeData?.extPayrollId || employeeData?.employeeNumber!,
      );
      setPdfUrlOffCycle(offCycleUrl);
    }
  }, [employeeData, selectedPayslipType, offCyclePaymentDate, regularCyclePaymentDate]);

  const offCyclePaymentDates = [
    { value: '2024-12-04', label: t('Payslips.4thDecember') },
    { value: '2024-12-27', label: t('Payslips.27thDecember') },
    { value: '2025-04-09', label: t('Payslips.09thApril') },
  ];

  const availableOffCyclePaymentDates = offCyclePaymentDates.filter((date) => {
    const targetDate = new Date(date.value);
    return new Date() >= targetDate;
  });

  const handlePayslipTypeChange = (value: string) => {
    setSelectedPayslipType(value);
    setPdfUrlRegularCycle(undefined);
    setPdfUrlOffCycle(undefined);
  };

  const handleOffCyclePaymentDateChange = (value: string) => {
    setOffCyclePaymentDate(value);
    setPdfUrlOffCycle(undefined);
  };

  return (
    <Stack spacing={0}>
      <Box mt={5} mb={5}>
        <Flex>
          <FormControl mr={5}>
            <FormControl.Label htmlFor='country-select'>
              {t('Payslips.payslipTypes')}
            </FormControl.Label>
            <Dropdown
              id='payslip-type-select'
              width='250px'
              value={[selectedPayslipType]}
              onChange={(value: string) => handlePayslipTypeChange(value)}>
              <Dropdown.Option value='regularPayment'>
                {t('Payslips.regularPayments')}
              </Dropdown.Option>
              <Dropdown.Option value='offCyclePayment'>
                {t('Payslips.offCyclePayments')}
              </Dropdown.Option>
            </Dropdown>
          </FormControl>
          {selectedPayslipType === 'offCyclePayment' && (
            <FormControl>
              <FormControl.Label htmlFor='country-select'>
                {t('Payslips.paymentDate')}
              </FormControl.Label>
              <Dropdown
                id='offcyle-date-select'
                width='250px'
                placeholder={t('Payslips.chooseOption')}
                value={[selectedPayslipType]}
                onChange={(value: string) => handleOffCyclePaymentDateChange(value)}>
                {availableOffCyclePaymentDates.map((date) => (
                  <Dropdown.Option key={date.value} value={date.value}>
                    {date.label}
                  </Dropdown.Option>
                ))}
              </Dropdown>
            </FormControl>
          )}
        </Flex>
      </Box>

      {selectedPayslipType === 'regularPayment' &&
        regularCyclePaymentDate &&
        pdfUrlRegularCycle && (
          <>
            <Box bg='slate.100' p={2}>
              <PeriodeCarousel
                onSelectionChange={(periode) => {
                  setRegularCyclePaymentDate(periode);
                }}
                initialSelection={periodes.length - 1}
                periodes={periodes}
              />
            </Box>
            <PdfViewer authScopes={[APP_SCOPE]} url={pdfUrlRegularCycle} />
          </>
        )}
      {selectedPayslipType === 'offCyclePayment' && offCyclePaymentDate && pdfUrlOffCycle && (
        <PdfViewer authScopes={[APP_SCOPE]} url={pdfUrlOffCycle} />
      )}
    </Stack>
  );
};
